import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import SelectShip from "./SelectShip";
import PlayMission from "./PlayMission";

const StartMission = () => {
  const { apiUrl } = useAuth();
  const [selectedShip, setSelectedShip] = useState(null);
  const [shipStats, setShipStats] = useState({});

  const shipClickHandler = (ship) => {
    setSelectedShip(ship);
    axios
      .get(`${apiUrl}/get_ship_stats/`, {
        params: {
          ship_id: ship.ship_id,
        },
      })
      .then((response) => {
        setShipStats(response.data.result);
      });
  };

  return (
    <div>
      {selectedShip === null ? (
        <SelectShip shipClickHandler={shipClickHandler} />
      ) : (
        <PlayMission
          selectedShip={selectedShip}
          shipStats={shipStats}
          setShipStats={setShipStats}
        />
      )}
    </div>
  );
};

export default StartMission;
