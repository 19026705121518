import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import { useAuth } from "../../../AuthContext";

const InfamyLeaderboard = () => {
  const { apiUrl } = useAuth();
  const [leaderboard, setLeaderboard] = useState([]);
  useEffect(() => {
    const cachedLeaderboardData = Cookies.get("infamyLeaderboard");

    if (cachedLeaderboardData) {
      setLeaderboard(JSON.parse(cachedLeaderboardData));
    } else {
      axios
        .get(`${apiUrl}/infamy_leaderboard_data/`)
        .then((response) => {
          if (response.data.result) {
            Cookies.set(
              "infamyLeaderboard",
              JSON.stringify(response.data.data),
              {
                expires: 1 / 288,
              }
            );
            setLeaderboard(response.data.data);
          } else {
            console.error("Error");
          }
        })
        .catch((error) => console.error("Error fetching data:"));
    }
  }, [apiUrl]);

  return (
    <div className="leaderboard-container">
      <h2 className="leaderboard-title">Infamy Leaderboard</h2>
      <table className="leaderboard-table">
        <thead>
          <tr className="table-header-row">
            <th>Pirate</th>
            <th>Day 1</th>
            <th>Day 2</th>
            <th>Day 3</th>
            <th>Day 4</th>
            <th>Day 5</th>
            <th>Day 6</th>
            <th>Day 7</th>
            <th>Weekly Points</th>
          </tr>
        </thead>
        <tbody>
          {leaderboard.map((user) => (
            <tr key={user.id} className="table-data-row">
              <td className="username-cell">{user.hive_user_name}</td>
              <td>{user.day_1_points}</td>
              <td>{user.day_2_points}</td>
              <td>{user.day_3_points}</td>
              <td>{user.day_4_points}</td>
              <td>{user.day_5_points}</td>
              <td>{user.day_6_points}</td>
              <td>{user.day_7_points}</td>
              <td className="weekly-points-cell">{user.total_weekly_points}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InfamyLeaderboard;
