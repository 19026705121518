import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addCrew, resetCrewCount, setBuildShipButton } from "../../../store";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import CurrentCard from "./CurrentCard";
import ShowCards from "./ShowCards/ShowCards";
import "./LoadShip.css";
import SelectShipRole from "./SelectShipRole";

const LoadShip = () => {
  const { user, apiUrl, userData, userNftCards } = useAuth();
  const [cards, setCards] = useState([]);
  // const [error, setError] = useState(null);
  const [shipImageCount, setShipImageCount] = useState([]);
  const [pirateImageCount, setPirateImageCount] = useState([]);
  const [pirateCardsDetails, setPirateCardsDetails] = useState([]);
  const [maxCrew, setMaxCrew] = useState(0);
  const [crewCount, setCrewCount] = useState(0);
  const [refreshData, setRefreshData] = useState(false);
  const [selectingPirateRole, setSelectingPirateRole] = useState(false);
  const [showAddCrewOption, setShowAddCrewOption] = useState(false);
  const [assignedPirates, setAssignedPirates] = useState([]);
  const [currentPirate, setCurrentPirate] = useState(null);
  const [currentCardImagePath, setCurrentCardImagePath] = useState("");
  const [selectedShip, setSelectedShip] = useState(null);
  const [lowResourceWarning, setLowResourceWarning] = useState(false);
  const [roles, setRoles] = useState({
    captain: null,
    quartermaster: null,
    navigator: null,
    boatswain: null,
    mastergunner: null,
    surgeon: null,
    carpenter: null,
    lookout: null,
    cook: null,
    crew: [],
  });

  const resourcesRequired = {
    Legendary: {
      copper: 15,
      iron: 25,
      wood: 75,
    },
    Epic: {
      copper: 7,
      iron: 15,
      wood: 50,
    },
    Rare: {
      copper: 3,
      iron: 8,
      wood: 30,
    },
    Common: {
      copper: 2,
      iron: 5,
      wood: 25,
    },
  };

  const crewCounter = useSelector((state) => state.crewCount);
  const dispatch = useDispatch();

  const handleAddCrew = (crewToAdd) => {
    dispatch(addCrew(crewToAdd));
  };

  const shipRoles = {
    captain: null,
    quartermaster: null,
    navigator: null,
    boatswain: null,
    mastergunner: null,
    surgeon: null,
    lookout: null,
    cook: null,
    crew: [],
  };

  useEffect(() => {
    const fetchShipData = async () => {
      try {
        const res = await axios.get(`${apiUrl}/get_ship_data/`, {
          params: {
            hive_user_name: user,
          },
        });
        return res.data.result;
      } catch (err) {
        console.error("Error");
      }
    };

    let userShipData = [];

    fetchShipData().then((responseLocal) => {
      for (let res in responseLocal) {
        userShipData = [...userShipData, ...responseLocal[res]["all_crew_ids"]];
      }
      const availableShipPirates = userNftCards.filter((nfts) => {
        return !userShipData.includes(nfts["_id"]);
      });
      setCards(availableShipPirates);
      const shipCardCounts = availableShipPirates.reduce((counts, card) => {
        const cardType = JSON.parse(card.properties.stats).type;
        if (cardType === "ship") {
          const cardName = card.properties.name;
          const rarity = JSON.parse(card.properties.nft).rarity;
          const id = JSON.parse(card._id);
          const capacity = JSON.parse(card.properties.stats)["max crew"];
          const key = `${rarity}_${cardName}_${id}_${capacity}`;
          counts[key] = (counts[key] || 0) + 1;
        }
        return counts;
      }, {});
      const shipCardSetArray = Object.entries(shipCardCounts).map(
        ([key, count]) => {
          const [rarity, name, id, capacity] = key.split("_");
          return { rarity, name, count, id, capacity };
        }
      );
      setShipImageCount(shipCardSetArray);

      const pirateCardCounts = availableShipPirates.reduce((counts, card) => {
        const cardType = JSON.parse(card.properties.stats).type;
        if (cardType === "pirate") {
          const cardName = card.properties.name;
          const rarity = JSON.parse(card.properties.nft).rarity;
          const id = JSON.parse(card._id);
          const key = `${rarity}_${cardName}_${id}`;
          counts[key] = (counts[key] || 0) + 1;
        }
        return counts;
      }, {});
      const pirateCardSetArray = Object.entries(pirateCardCounts).map(
        ([key, count]) => {
          const [rarity, name, id] = key.split("_");
          return { rarity, name, count, id };
        }
      );
      setPirateImageCount(pirateCardSetArray);
      // Original set of pirate cards
      setPirateCardsDetails(pirateCardSetArray);
    });
  }, [user, apiUrl, refreshData, userNftCards]);

  const handleShipClick = (shipName, shipId, imagePath, rarity) => {
    const currentResourcesRequired = resourcesRequired[rarity];
    if (
      !(
        userData.items.wood >= currentResourcesRequired.wood &&
        userData.items.iron >= currentResourcesRequired.iron &&
        userData.items.copper >= currentResourcesRequired.copper
      )
    ) {
      setLowResourceWarning(true);
      setTimeout(() => {
        setLowResourceWarning(false);
      }, 4000);
      // window.alert("You do not have enough resources");
      return;
    }
    setSelectedShip({
      name: shipName,
      id: shipId,
    });
    setCurrentCardImagePath(imagePath);
    setRoles(shipRoles);

    const currentShip = shipImageCount.find((cur) => shipId === cur.id);
    setMaxCrew(currentShip.capacity);
  };

  const handleRoleAssignment = (pirateName, pirateId) => {
    setCurrentPirate({
      name: pirateName,
      id: pirateId,
    });
    setSelectingPirateRole(true);
  };

  const allRolesSelected = (roles) => {
    return Object.entries(roles).every(([key, value]) => {
      return key === "crew" || value !== null;
    });
  };

  const handlePirateSelection = (pirateInfo, role) => {
    setRoles((prevRoles) => {
      const updatedRoles = { ...prevRoles, [role]: pirateInfo };

      if (allRolesSelected(updatedRoles)) {
        setShowAddCrewOption(true);

        const assignedPirates_ = Object.values(updatedRoles)
          .filter((shipRole) => shipRole !== null && shipRole.id)
          .map((shipRole) => parseInt(shipRole.id));
        setAssignedPirates(assignedPirates_);
      }

      setCrewCount((prevCount) => prevCount + 1);

      handleAddCrew(1);

      return updatedRoles;
    });

    setSelectingPirateRole(false);
    setPirateImageCount((prevPirates) =>
      prevPirates.filter((pirates) => pirates.id !== pirateInfo.id)
    );
  };

  useEffect(() => {}, [crewCount, crewCounter]);

  const closeCard = () => {
    setSelectedShip(null);
    setRoles({});
    setPirateImageCount(pirateCardsDetails);
    setCrewCount(0);
    setShowAddCrewOption(false);
    dispatch(resetCrewCount());
    dispatch(setBuildShipButton(false));
  };

  const closePirateSelection = () => {
    setSelectingPirateRole(false);
  };

  return (
    <div>
      {lowResourceWarning ? (
        ""
      ) : !selectedShip ? (
        <div className="resources-container">
          <h2 className="resources-title">Resources Needed to Build Ship</h2>
          <div className="ship-type">
            <h3 className="ship-type-title">Legendary</h3>
            <ul className="resources-list">
              <li>
                <span className="resource-label">Copper:</span>{" "}
                <span className="resource-value">15</span>
              </li>
              <li>
                <span className="resource-label">Iron:</span>{" "}
                <span className="resource-value">25</span>
              </li>
              <li>
                <span className="resource-label">Wood:</span>{" "}
                <span className="resource-value">75</span>
              </li>
            </ul>
          </div>
          <div className="ship-type">
            <h3 className="ship-type-title">Epic</h3>
            <ul className="resources-list">
              <li>
                <span className="resource-label">Copper:</span>{" "}
                <span className="resource-value">7</span>
              </li>
              <li>
                <span className="resource-label">Iron:</span>{" "}
                <span className="resource-value">15</span>
              </li>
              <li>
                <span className="resource-label">Wood:</span>{" "}
                <span className="resource-value">50</span>
              </li>
            </ul>
          </div>
          <div className="ship-type">
            <h3 className="ship-type-title">Rare</h3>
            <ul className="resources-list">
              <li>
                <span className="resource-label">Copper:</span>{" "}
                <span className="resource-value">3</span>
              </li>
              <li>
                <span className="resource-label">Iron:</span>{" "}
                <span className="resource-value">8</span>
              </li>
              <li>
                <span className="resource-label">Wood:</span>{" "}
                <span className="resource-value">30</span>
              </li>
            </ul>
          </div>
          <div className="ship-type">
            <h3 className="ship-type-title">Common</h3>
            <ul className="resources-list">
              <li>
                <span className="resource-label">Copper:</span>{" "}
                <span className="resource-value">2</span>
              </li>
              <li>
                <span className="resource-label">Iron:</span>{" "}
                <span className="resource-value">5</span>
              </li>
              <li>
                <span className="resource-label">Wood:</span>{" "}
                <span className="resource-value">25</span>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}

      {!selectedShip ? (
        <ShowCards
          cardSet={shipImageCount}
          lowResourceWarning={lowResourceWarning}
          clickHandler={(shipName, shipId, imagePath, rarity) =>
            handleShipClick(shipName, shipId, imagePath, rarity)
          }
        />
      ) : (
        <>
          <CurrentCard
            allData={cards}
            currentImagePath={currentCardImagePath}
            currentCard={selectedShip.name}
            shipData={{ ship: selectedShip, ...roles }}
            closeCard={() => closeCard()}
            maxCrew={maxCrew}
            crewCount={crewCount}
            showAddCrewOption={showAddCrewOption}
            setShowAddCrewOption={setShowAddCrewOption}
            assignedPirates={assignedPirates}
            setAssignedPirates={setAssignedPirates}
            setRefreshData={setRefreshData}
          />
          {selectingPirateRole ? (
            <SelectShipRole
              pirate={currentPirate}
              shipRole={roles}
              handlePirateSelection={(pirateInfo, role) => {
                handlePirateSelection(pirateInfo, role);
              }}
              closeButton={closePirateSelection}
            />
          ) : (
            ""
          )}

          <ShowCards
            cardSet={pirateImageCount}
            clickHandler={(pirateName, pirateId, imagePath) => {
              handleRoleAssignment(pirateName, pirateId, imagePath);
            }}
          />
        </>
      )}
    </div>
  );
};

export default LoadShip;
