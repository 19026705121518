import axios from "axios";
import React, { useState } from "react";
import { useAuth } from "../../../AuthContext";
import OutcomeMessage from "./OutcomeMessage";

const CurrentMission = ({
  currentMission,
  shipId,
  shipStats,
  setShipStats,
  selectedBooster,
  setSelectedBooster,
}) => {
  const [resultMessage, setResultMessage] = useState();
  const [showMissionButton, setShowMissionButton] = useState(true);
  const [eventHeading, setEventHeading] = useState("");
  const [infamyPoints, setInfamyPoints] = useState(0);
  const { apiUrl, userData, setUpdateData } = useAuth();
  const missionToBeDone = [
    "Plan Raid",
    "Precision Fire",
    "Decipher Treasure Map",
    "Treasure Spotter",
    "Food Expedition",
    "Create Bombs and Traps",
  ];
  const ImagePath = `/CardImages/missionImages/${currentMission
    .split(" ")
    .join("")
    .toLowerCase()}.png`;
  const currentMissionHandler = () => {
    console.log(selectedBooster);
    setShowMissionButton(false);
    const params = {
      mission: currentMission,
      shipId: shipId,
      booster: selectedBooster,
      player_id: userData.discord_id,
    };
    axios.post(`${apiUrl}/play_mission/`, params).then((response) => {
      const responseData = response.data.result;
      setResultMessage(responseData.outcome_message);
      if (responseData.result) {
        setInfamyPoints(responseData.infamy_points);
        axios
          .get(`${apiUrl}/get_ship_stats/`, {
            params: {
              ship_id: shipId,
            },
          })
          .then((response) => {
            setShipStats(response.data.result);
            console.log(response.data.result);
            setUpdateData((prevData) => !prevData);
          });
      } else {
        setInfamyPoints(0.25);
      }
      console.log(responseData.event_triggered);
      if (responseData.event_triggered) {
        console.log(responseData.event_data.event_data);
        setEventHeading(responseData.event_data.event_data.event_message);
        setResultMessage(responseData.event_data.event_data.crew_feeling);
      }
    });
  };

  const getProgressBarColor = (value) => {
    if (value < 25) return "#4caf50"; // Green
    if (value >= 25 && value < 50) return "#9c27b0"; // Purple
    if (value >= 50 && value < 75) return "#ff9800"; // Orange
    return "#f44336"; // Red
  };

  const closeButtonHandler = () => {
    setShowMissionButton(true);
    setEventHeading("");
    setInfamyPoints(0);
    setResultMessage("");
    setSelectedBooster("None");
  };

  const boosters = {
    "Boost Morale": {
      "1 Rum": {
        rum: 1,
        info: "Reduce mutiny meter 3-15%",
      },
    },
    "Plunder Distribution": {
      "3 Rum": {
        rum: 3,
        info: "Reduce mutiny meter 15-35%",
      },
    },
    "Crew Discipline": {
      "2 Rope": {
        rope: 2,
        info: "Reduce mutiny meter 10-25%",
      },
      Spyglass: {
        spyglass: 1,
        info: "Reduce mutiny meter 5-15%",
      },
    },
    "Chart Course": {
      "1 Map": {
        map: 1,
        info: "Reduce sailing time meter 5-20%",
      },
    },
    "Repair Ship": {
      "3 Wood": {
        wood: 3,
        info: "Reduce ship wreck meter 5-15%",
      },
      "2 Iron": {
        iron: 2,
        info: "Reduce ship wreck meter 10-20%",
      },
      "1 Copper": {
        copper: 1,
        info: "Reduce ship wreck meter 15-35%",
      },
    },
    "Armory Management": {
      "1 Gunpowder": {
        gunpowder: 1,
        info: "Reduce attack risk meter 5-20%",
      },
      "2 Iron": {
        iron: 2,
        info: "Reduce attack risk meter 5-20%",
      },
    },
    "Heal Crew": {
      "1 Herbs": {
        herbs: 1,
        info: "Reduce crew sickness meter 5-20%",
      },
    },
    "Eagle Eyes": {
      SpyGlass: {
        spyglass: 1,
        info: "Reduce attack risk meter 5-20%",
      },
    },
    "Hearty Meals": {
      "3 Small Fish": {
        small_fish: 3,
        info: "Reduce hunger meter 5-15%",
      },
      "2 Medium Fish": {
        medium_fish: 2,
        info: "Reduce hunger meter 10-25%",
      },
      "1 Large Fish": {
        large_fish: 1,
        info: "Reduce hunger meter 15-35%",
      },
    },
  };

  const boosterHandler = (currentBooster) => {
    const userBooster = userData.items;
    if (currentBooster !== "None") {
      Object.keys(currentBooster).forEach((key) => {
        if (key !== "info") {
          const requiredAmount = currentBooster[key];
          const userHasAmount = userBooster[key];
          if (userHasAmount >= requiredAmount) {
            setSelectedBooster({ [key]: currentBooster[key] });
          } else {
            setResultMessage(`You do not have enough ${[key]}.`);
            setShowMissionButton(false);
            setInfamyPoints(0.25);
          }
        }
      });
    }
  };

  return (
    <div className="mission-image-container">
      <h3>{currentMission}</h3>
      <img className="mission-image" src={ImagePath} alt={currentMission} />
      {missionToBeDone.includes(currentMission) ? (
        <p>To be done in next update...</p>
      ) : showMissionButton ? (
        <div>
          {boosters[currentMission] && (
            <>
              <div>
                <label key="">
                  <input
                    type="radio"
                    name="booster"
                    value="None"
                    // checked={selectedBooster === ""}
                    onChange={(e) => boosterHandler(e.target.value)}
                  />
                  <span>None</span>
                </label>
              </div>
              {Object.keys(boosters[currentMission]).map((booster) => (
                <label key={booster}>
                  <input
                    type="radio"
                    name="booster"
                    value={booster}
                    onChange={(e) =>
                      boosterHandler(boosters[currentMission][e.target.value])
                    } // Assuming you have a state to track selected booster
                  />
                  {booster}
                  <p>{boosters[currentMission][booster].info}</p>
                </label>
              ))}
            </>
          )}
          <button
            className="play-mission-button"
            onClick={currentMissionHandler}
          >
            {currentMission}
          </button>
        </div>
      ) : (
        <OutcomeMessage
          resultMessage={resultMessage}
          closeButtonHandler={closeButtonHandler}
          eventHeading={eventHeading}
          infamyPoints={infamyPoints}
        />
      )}

      <h3>Ship Stats</h3>
      <div className="stat-container">
        {shipStats
          ? Object.entries(shipStats).map(([key, value]) => {
              if (key === "ship_id") return null;
              return (
                <div key={key} className="stat-item">
                  <strong>
                    {key
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (l) => l.toUpperCase())}
                    :
                  </strong>{" "}
                  <div className="progress-bar-container">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${value}%`,
                        backgroundColor: getProgressBarColor(value),
                      }}
                    ></div>
                  </div>
                </div>
              );
            })
          : "Data Not Available"}
      </div>
    </div>
  );
};

export default CurrentMission;
