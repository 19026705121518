import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext";
import "./Open.css";
import coverSoundClick from "./card_open.wav";

const OpenPacks = () => {
  const { user, userData, apiUrl } = useAuth();
  const [packContents, setPackContents] = useState([]);
  const [showCards, setShowCards] = useState(false);
  const [clickedImages, setClickedImages] = useState({});
  const [currentPacks, setCurrentPacks] = useState(1);
  const [userPacks, setUserPacks] = useState(userData.Packs);

  const params = {
    username: user,
    number_of_packs: { currentPacks },
  };

  const open_sound = new Audio(coverSoundClick);
  const handlePacks = async () => {
    const response = await axios.post(`${apiUrl}/open_packs/`, params);
    const result = response.data["card_contents"];
    const packResult = [];
    for (let card in result) {
      packResult.push(result[card]);
    }
    setPackContents(packResult);
    setShowCards(true);
  };

  const handleImageClick = (cardImagePath) => {
    setClickedImages((prevState) => ({
      ...prevState,
      [cardImagePath]: true,
    }));
    playCoverSoundClick();
  };

  const playCoverSoundClick = () => {
    open_sound.currentTime = 0;
    open_sound.play();
  };
  const coverImagePath = "/CardImages/coverImage.jpg";

  const handlePackChange = (event) => {
    if (userPacks >= event.target.value) {
      setCurrentPacks(event.target.value);
    }
  };

  const handleCloseButton = () => {
    setShowCards(false);
    setUserPacks(userPacks - currentPacks);
  };

  return (
    <div>
      <div className="pack-open-content">
        <div>
          {showCards ? (
            <div className="cards-container">
              <button onClick={handleCloseButton} className="button-close">
                x
              </button>
              <ul className="image-container">
                {packContents.map((card, index) => {
                  const cardImagePath = `/CardImages/${
                    card.nft.rarity
                  }/${card.name.split(" ").join("_")}.png`;
                  return (
                    <div key={index}>
                      <li className="card-item" key={index}>
                        <img
                          className="card-image"
                          src={
                            clickedImages[cardImagePath]
                              ? cardImagePath
                              : coverImagePath
                          }
                          alt={card.name}
                          onClick={() => handleImageClick(cardImagePath)}
                        />
                      </li>
                    </div>
                  );
                })}
              </ul>
            </div>
          ) : (
            <div>
              <img
                className="card-image"
                src={coverImagePath}
                alt="No Packs Available"
              />
              <p>Available Packs: {userPacks}</p>
              <div>
                <p>Open Packs: {currentPacks}</p>
                <input
                  type="range"
                  min="1"
                  max="3"
                  value={currentPacks}
                  onChange={handlePackChange}
                />
                <br />
                <button onClick={handlePacks}>Open Packs</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OpenPacks;
