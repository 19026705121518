import React, { useState } from "react";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import ResourceDisplay from "./ResourceDisplay";

const OpenChest = () => {
  const { userData, apiUrl, setUpdateData } = useAuth();
  const [chestsToOpen, setChestsToOpen] = useState(1); // Default to opening 1 chest
  const [errorMessage, setErrorMessage] = useState("");
  const [chestItems, setChestItems] = useState("");

  const handleOpenChests = async () => {
    if (chestsToOpen < 1 || chestsToOpen > 10) {
      setErrorMessage("You can open between 1 to 10 chests.");
    } else if (chestsToOpen > userData.items.chests) {
      setErrorMessage(
        `You don't have enough chests. You have ${userData.items.chests} left.`
      );
    } else {
      // Proceed with opening the chests and update user data
      setErrorMessage(""); // Clear any error messages
      const token = localStorage.getItem("token");

      const params = {
        discord_id: userData.discord_id,
        number_chest: chestsToOpen, // Fix the way number_of_chests is set
        method: "WEBAPP",
      };

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Make sure you handle the response with async/await or .then
      try {
        const response = await axios.put(`${apiUrl}/open_chests/`, params, {
          headers,
        });
        setChestItems(response.data.data);
        setUpdateData((prevValue) => !prevValue);
      } catch (error) {
        console.error("Error opening chests:");
      }
    }
  };
  return (
    <div>
      {chestItems !== "" ? (
        <ResourceDisplay resources={chestItems} setChestItems={setChestItems} />
      ) : (
        <div className="shop-container buy-packs-container">
          <img
            className="chest-image"
            src="/CardImages/others/chests.png"
            alt="chest"
          />
          <div className="buy-packs-container">
            <h3>Chests Available: {userData.items.chests}</h3>

            <div>
              <h3>Open Chests:</h3>
              <input
                type="number"
                value={chestsToOpen}
                onChange={(e) => setChestsToOpen(Number(e.target.value))}
                min="1"
                max="5"
                step="1"
                className="input-text"
              />
              <button onClick={handleOpenChests}>Open Chests</button>
            </div>

            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default OpenChest;
