import React from "react";

// Define an icon map linking each resource to its icon path
const resourceIcons = {
  chests: "/CardImages/others/chestItems/chests.png",
  coins: "/CardImages/others/chestItems/coin.png",
  copper: "/CardImages/others/chestItems/copper.png",
  gunpowder: "/CardImages/others/chestItems/gunpowder.png",
  herbs: "/CardImages/others/chestItems/herbs.png",
  iron: "/CardImages/others/chestItems/iron.png",
  large_fish: "/CardImages/others/chestItems/large_fish.png",
  map: "/CardImages/others/chestItems/map.png",
  medium_fish: "/CardImages/others/chestItems/medium_fish.png",
  rope: "/CardImages/others/chestItems/rope.png",
  rum: "/CardImages/others/chestItems/rum.png",
  small_fish: "/CardImages/others/chestItems/small_fish.png",
  spyglass: "/CardImages/others/chestItems/spyglass.png",
  wood: "/CardImages/others/chestItems/wood.png",
};

const ResourceDisplay = ({ resources, setChestItems }) => {
  const handleCloseButton = () => {
    setChestItems("");
  };
  return (
    <div>
      <div className="overlay">
        <div className="sell-container resource-container">
          <button className="close-button" onClick={handleCloseButton}>
            x
          </button>
          {Object.entries(resources).map(
            ([resource, amount]) =>
              amount > 0 && (
                <div key={resource} className="resource-item">
                  <img
                    src={resourceIcons[resource]}
                    alt={resource}
                    className="resource-icon"
                  />
                  <span>
                    {resource
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                    : {amount}
                  </span>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default ResourceDisplay;
