import React, { useState } from "react";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import ResourceDisplay from "../Open/ResourceDisplay";

const UserStats = () => {
  const { user, userData, apiUrl, updateData, setUpdateData } = useAuth();
  const [showWithdrawTab, setShowWithdrawTab] = useState(false);
  const [currentCoin, setCurrentCoin] = useState(1);
  const [withdrawalConfirmed, setWithdrawalConfirmed] = useState(false);
  const [showDataNftPacks, setShowDataNftPacks] = useState(false);
  const [claimPacksRequest, setClaimPacksRequest] = useState(false);
  const [userItems, setUserItems] = useState("");
  const userCoins = parseInt(userData.coins);
  const nftsLeft = userData.nfts % 3;
  const convertNfts = nftsLeft === 0 ? userData.nfts : userData.nfts - nftsLeft;
  const claimPacks = parseInt(userData.nfts / 3);

  console.log("here are user nfts", claimPacks);

  const handleShowItems = () => {
    setUserItems(userData.items);
  };

  const renderUserData = () => {
    return Object.entries(userData)
      .filter(([key]) => key !== "discord_id" && key !== "items")
      .map(([key, value]) => (
        <div key={key} className="user-data-item">
          <p>
            <strong>{key}</strong>: {value}
          </p>
        </div>
      ));
  };

  const showWithdraw = () => {
    setShowWithdrawTab(true);
    setWithdrawalConfirmed(false);
  };

  const closewithdraw = () => {
    setShowWithdrawTab(false);
  };

  const lootcoinchange = (e) => {
    setCurrentCoin(e.target.value);
  };

  const showButtonNftPacks = () => {
    setShowDataNftPacks(true);
  };
  const closeConvertNft = () => {
    setShowDataNftPacks(false);
  };

  const withdrawLoot = async () => {
    const keychain = window.hive_keychain;
    const payload = {
      contractPayload: {
        symbol: "LOOT",
        quantity: currentCoin,
        memo: `${currentCoin}, ${userCoins - currentCoin}`,
      },
    };

    try {
      keychain.requestCustomJson(
        user,
        "piratesaga",
        "Active",
        JSON.stringify(payload),
        `withdraw ${currentCoin} loot`,
        async (response) => {
          if (response.success) {
            try {
              setWithdrawalConfirmed(true);
              const params = {
                hive_user_name: user,
                amount: currentCoin,
              };
              await axios.post(`${apiUrl}/withdraw_loot/`, params);
              setUpdateData(!updateData);
            } catch (error) {}
          } else {
          }
        }
      );
    } catch (error) {}
  };

  const convertPacks = () => {
    const keychain = window.hive_keychain;
    const payload = {
      memo: `Claim packs: ${claimPacks}, Nfts Available: ${userData.nfts}, Nfts Left: ${nftsLeft}, User Packs: ${userData.packs}`,
    };

    try {
      keychain.requestCustomJson(
        user,
        "piratesaga",
        "Active",
        JSON.stringify(payload),
        `Claim packs: ${claimPacks}, Nfts Available: ${userData.nfts}, Nfts Left: ${nftsLeft}, User Packs: ${userData.packs}`,
        async (response) => {
          if (response.success) {
            try {
              const params = {
                hive_user_name: user,
                claim_packs: claimPacks,
                nfts_available: userData.nfts,
                nfts_left: nftsLeft,
              };
              await axios.post(`${apiUrl}/convert_nft_packs/`, params);
              setClaimPacksRequest(true);
              setUpdateData(!updateData);
            } catch (error) {}
          } else {
          }
        }
      );
    } catch (error) {}
  };

  return (
    <div className="user-stats-container">
      {userItems !== "" ? (
        <ResourceDisplay resources={userItems} setChestItems={setUserItems} />
      ) : (
        ""
      )}
      {showWithdrawTab ? (
        <div>
          <div className="overlay"> </div>
          <div className="show-withdraw">
            <button className="close-button" onClick={closewithdraw}>
              x
            </button>
            {withdrawalConfirmed ? (
              "Withdrawal request confirmed!"
            ) : (
              <div>
                <p>Withdraw loot coins</p>
                <input
                  type="number"
                  min={1}
                  max={userCoins}
                  onChange={lootcoinchange}
                />
                {currentCoin > userCoins ? (
                  "You do not have enough balance!"
                ) : (
                  <button onClick={withdrawLoot}>Withdraw Loot</button>
                )}
              </div>
            )}
          </div>
        </div>
      ) : null}
      <p className="welcome-message">Welcome {user}. Here is your data...</p>
      <button onClick={handleShowItems}>Show chest items.</button>

      <button className="withdraw-button" onClick={showWithdraw}>
        Withdraw Loot
      </button>
      {claimPacks >= 1 ? (
        <button className="claim-packs" onClick={showButtonNftPacks}>
          Claim {claimPacks} Packs
        </button>
      ) : (
        ""
      )}
      {showDataNftPacks ? (
        <div>
          <div className="overlay"></div>
          <div className="show-withdraw">
            <button className="close-button" onClick={closeConvertNft}>
              x
            </button>
            <br />
            {!claimPacksRequest ? (
              <div>
                <p>
                  Convert {convertNfts} ingame nfts into {claimPacks} packs.{" "}
                  {nftsLeft > 0 ? `You will be left with ${nftsLeft} nfts` : ""}
                </p>
                <button onClick={convertPacks}>Claim {claimPacks} packs</button>
              </div>
            ) : (
              <p>Request made successfully!</p>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="user-data">{renderUserData()}</div>
    </div>
  );
};

export default UserStats;
