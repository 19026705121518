import React, { useState } from "react";
import Navbar from "../NavBar/NavBar";
import BuyPacks from "./Buypacks";
import BuyChests from "./BuyChests";

const Shop = () => {
  const [currentAction, setCurrentAction] = useState("buypacks");
  const currentWindow = {
    buypacks: BuyPacks,
    buychests: BuyChests,
  };
  const handleClick = (value) => {
    setCurrentAction(value);
  };

  const ComponentToRender = currentWindow[currentAction];

  return (
    <div>
      <Navbar />
      <div>
        <div className="heading-details">
          <h3
            onClick={() => handleClick("buypacks")}
            className={`shop-option ${
              currentAction === "buypacks" ? "active" : ""
            }`}
          >
            Buy Packs
          </h3>
          <h3
            onClick={() => handleClick("buychests")}
            className={`shop-option ${
              currentAction === "buychests" ? "active" : ""
            }`}
          >
            Buy Chests
          </h3>
        </div>
        <div className="shop-content">
          {ComponentToRender && <ComponentToRender />}
        </div>
      </div>
    </div>
  );
};

export default Shop;
