import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  // const [apiUrl, setApiUrl] = useState("http://127.0.0.1:8000");
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isActive, setActive] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [userNftCards, setUserNftCards] = useState([]);

  const apiUrl = "https://piratesaga.online";
  // const apiUrl = "http://127.0.0.1:8000";
  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    try {
      if (token) {
        const response = await axios.get(`${apiUrl}/get_user_account/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const userName = response.data.message.userName;
        setIsAuthenticated(true);
        setUser(userName);
        setUserData(response.data.message);
        setActive(response.data.message.account_status);
      }
    } catch (error) {}
  };

  const saveToLocalStorage = (key, value, expirationTimeInMs) => {
    const data = {
      value: value,
      timestamp: new Date().getTime(), // Store the current time in milliseconds
      expirationTime: expirationTimeInMs, // Expiration time in milliseconds (e.g., 1 hour = 3600000 ms)
    };
    localStorage.setItem(key, JSON.stringify(data));
  };

  const getFromLocalStorage = (key) => {
    const storedData = localStorage.getItem(key);

    if (!storedData) {
      return null; // Data doesn't exist
    }

    const data = JSON.parse(storedData);
    const currentTime = new Date().getTime();

    // Check if the data has expired
    if (currentTime - data.timestamp > data.expirationTime) {
      localStorage.removeItem(key); // Remove expired data
      return null; // Data is expired
    }

    return data.value; // Return the actual value if it hasn't expired
  };

  useEffect(() => {
    const userCardsCached = getFromLocalStorage("userCardsNfts");

    if (userCardsCached) {
      setUserNftCards(userCardsCached);
    } else {
      const fetchUserCards = async (username, offset) => {
        const apis = [
          "https://engine.beeswap.tools/contracts",
          "https://api.hive-engine.com/rpc/contracts",
          "https://herpc.dtools.dev/contracts",
          "https://engine.rishipanthee.com/contracts",
        ];
        const randomIndex = Math.floor(Math.random() * apis.length);
        const randomApi = apis[randomIndex];
        const params = {
          contract: "nft",
          table: "PIRATESAGAinstances",
          query: {
            account: username,
          },
          limit: 1000,
          offset: offset,
          indexes: [],
        };
        const j = {
          jsonrpc: "2.0",
          id: 1,
          method: "find",
          params: params,
        };

        try {
          const response = await axios.post(randomApi, j);
          const data = response.data;

          if (data.result.length === 1000) {
            data.result = data.result.concat(
              await fetchUserCards(username, offset + 1000)
            );
          }
          return data.result;
        } catch (error) {
          // setError(error);
          return [];
        }
      };
      fetchUserCards(user, 0).then((response) => {
        if (response.length > 0) {
          saveToLocalStorage("userCardsNfts", response, 3600000);
        }

        setUserNftCards(response);
      });
    }
  }, [user]);

  useEffect(() => {
    fetchUserData();

    const intervalId = setInterval(() => {
      fetchUserData();
    }, 10000000);

    return () => clearInterval(intervalId);
  }, [updateData]);

  const login = async (userName) => {
    let keychain = window.hive_keychain;

    keychain.requestSignBuffer(userName, "login", "Posting", (response) => {
      if (response.success === true) {
        // Send a request to the backend to generate a login token
        axios
          .post(`${apiUrl}/generate_login_token/`, {
            username: userName,
          })
          .then((response) => {
            // Extract the token from the response
            const token = response.data;

            // Store the token in local storage for future use
            localStorage.setItem("token", JSON.stringify(token));

            // Set the user and authentication status in the application state
            setUser(userName);
            setIsAuthenticated(true);
            return true;
          })
          .catch((error) => {
            // Handle errors during the request or response processing
            throw error;
          });
      }
    });
  };

  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        isAuthenticated,
        userData,
        setIsAuthenticated,
        setUser,
        isActive,
        apiUrl,
        updateData,
        setUpdateData,
        userNftCards,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
