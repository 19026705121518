import React, { useState } from "react";
import Navbar from "../NavBar/NavBar";
import OpenPacks from "./OpenPacks";
import OpenChest from "./OpenChests";

const Open = () => {
  const [currentAction, setCurrentAction] = useState("openPacks");
  const currentWindow = {
    openPacks: OpenPacks,
    openChests: OpenChest,
  };
  const handleClick = (value) => {
    setCurrentAction(value);
  };

  const ComponentToRender = currentWindow[currentAction];
  return (
    <div>
      <Navbar />
      <div>
        <div className="heading-details">
          <h3
            onClick={() => handleClick("openPacks")}
            className={`shop-option ${
              currentAction === "openPacks" ? "active" : ""
            }`}
          >
            Open Packs
          </h3>
          <h3
            onClick={() => handleClick("openChests")}
            className={`shop-option ${
              currentAction === "openChests" ? "active" : ""
            }`}
          >
            Open Chests
          </h3>
        </div>
        <div className="shop-content">
          {ComponentToRender && <ComponentToRender />}
        </div>
      </div>
    </div>
  );
};

export default Open;
